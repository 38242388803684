import Typography from "typography"
import sutroTheme from "typography-theme-sutro"

sutroTheme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

sutroTheme.googleFonts = [
  {
    name: "Sahitya",
    styles: [400],
  },
]

sutroTheme.headerFontFamily = ["Sahitya", "sans-serif"]

// fairyGatesTheme.bodyFontFamily = ["Encode Sans", "sans-serif"]

const typography = new Typography(sutroTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
