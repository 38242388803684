import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import ThemeToggler from "./ThemeToggler/ThemeToggler"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          color: "#E08F92",
          fontSize: "60px",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          marginTop: 0,

          color: "#E08F92",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div className="blog-background">
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
        className="blog-content"
      >
        <header className="title-and-toggle">
          {header}
          <ThemeToggler />
        </header>
        <main>{children}</main>
        <footer>
          {new Date().getFullYear()}, Made with ❤️ by
          {` `}
          <a href="https://alexbarba.github.io">Alexis Barba</a>
        </footer>
      </div>
    </div>
  )
}

export default Layout
