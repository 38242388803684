import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import Toggle from "react-toggle"
import { HiSun, HiMoon } from "react-icons/hi"
import "./style.css"

export default class extends React.Component {
  render() {
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <label htmlFor="themetoggler" id="themetoggler">
            <Toggle
              checked={theme === "dark"}
              icons={{
                checked: <HiMoon />,
                unchecked: <HiSun />,
              }}
              onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
            />
          </label>
        )}
      </ThemeToggler>
    )
  }
}
